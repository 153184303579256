import { ComponentType, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import Bearer from './Bearer'
import Header from './Header'
import Main from './Main'
import PageLoading from './PageLoading'
import Container from '../ui/Container'
import Background from './Backgorund'
import useDeleteData from '../../hooks/useDeleteData'
import { Delete, Loader, Popup } from '../ui'
import { getPageVisible, getShowPageLoader } from '../../store/viewSlice'
import usePopup from '../../hooks/usePopup'
import DeleteWrapper from '../../contexts/delete'
import { useAuthData } from '../../contexts/auth'
import { usePopupImage } from '../../hooks/usePopupImage'
import ImagePopup from './PopupImage'

export const withLayout =
  <T extends object>(Component: ComponentType<T>, needAuth?: true) =>
  (props: T): ReactElement => {
    const { deleteItem, itemToDelete, setDeleteItem } = useDeleteData()
    const { session, pending, errors } = useAuthData()

    const { popupContent, hidePopup } = usePopup()
    const { image, setImage } = usePopupImage()

    const pageVisible = useSelector(getPageVisible)


    const showLoader = useSelector(getShowPageLoader)

    return (
      <DeleteWrapper>
        <Container.Flex
          alignItems="stretch"
          styles={{ position: 'relative' }}
        >
          <Container.Flex
              styles={{
                  minHeight: 'calc(var(--vh, 1vh) * 100);',
              }}
          >
            <Background />
            <Header />
            <Container.Flex
              fullWidth
              fullHeight
              styles={{
                transition: 'opacity 400ms ease-in',
                opacity: pageVisible ? 1 : 0,
                minHeight: `calc(var(--vh, 1vh) * 100 - 112px)`,
              }}
            >
              {!!popupContent && (
                <Popup content={popupContent} hidePopup={hidePopup} />
              )}
                <Main>
                  {!needAuth && <Component {...props} />}
                  {needAuth && !pending && session.isAuthorised && (
                    <Component {...props} />
                  )}
                  {needAuth && pending && <PageLoading />}
                  {needAuth && !pending && !!errors.checkAuth.length && (
                    <Bearer />
                  )}
                  {needAuth && !pending && !session.isAuthorised && <Bearer />}

                  {!!itemToDelete && (
                    <Delete
                      onDelete={deleteItem}
                      onCancel={() => setDeleteItem(null)}
                      title="Удаление"
                      question="Вы уверены что хотите произвести удаление?"
                    />
                  )}

                  {image && (
                    <ImagePopup url={image} hide={() => setImage(null)} />
                  )}
                </Main>

            </Container.Flex>
          </Container.Flex>

          {showLoader && <Loader />}
        </Container.Flex>
      </DeleteWrapper>
    )
  }
