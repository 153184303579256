import { createApi } from '@reduxjs/toolkit/query/react'
import { baseURL } from '../../apinew'
import { Session } from '../userSlice/types'
import { axiosBaseQuery } from './axios.query'
import { URLS } from './URLS'
import { collectFormData } from './helpers/collectFormData'
import { OrderListData, SearchRequest } from '../../components/Profile/OrdersList/OrderListContext'

export const siteApi = createApi({
  reducerPath: 'siteApi',
  baseQuery: axiosBaseQuery({
    baseUrl: baseURL ?? '/',
  }),
  endpoints: builder => ({
    getOrderList: builder.query<{ data: OrderListData[] }, { data: SearchRequest, session: Session }>({
      query: ({ data, session }) => ({
        url: URLS.GetOrderList,
        method: "POST",
        data: collectFormData(data, session)
      })
    }),
    //QUERIES
    getClientDevices: builder.query<{ number: string }, void>({
      query: () => ({
        url: URLS.GetClientOrder,
        method: 'POST',
        data: collectFormData({}),
      }),
    }),
    getShowSetOrderReadyButton: builder.query<{ viewUiComplite: boolean }, Session>({
      query: session => ({
        url: URLS.ViewCompliteStatus,
        data: collectFormData({}, session),
        method: 'POST',
      }),
    }),
    // MUTATIONS
    sendSmsCode: builder.mutation<
      { isAuthorized: boolean; codeSent: boolean },
      { data: { phone: string }, session?: Session }
    >({
      query: ({data, session}) => ({
        url: URLS.SendSmsCode,
        data: collectFormData(data, session),
        method: 'POST',
      }),
    }),
    validSmsCode: builder.mutation<
      { isValid: boolean; message: string },
      { data:  { phone: string; code: string }, session?: Session }
    >({
      query: ({ data, session }) => ({
        url: URLS.ValidSmsCode,
        data: collectFormData(data, session),
        method: 'POST',
      }),
    }),
    updateOrderStatus: builder.mutation<{
      number: string,
      newStatus: string
    }, {
      data: {
        number: string,
        statusCode: string
      },
      session?: Session

    }>({
      query: ({ data, session }) => ({
        url: URLS.UpdateOrderStatus,
        data: collectFormData(data, session),
        method: "POST"
      })
    }),
    restorePassword: builder.mutation<{
      MESSAGE: string,
      TYPE: string
    }, { data: { loginEmail: string }, session?: Session }>({
      query: ({ data, session }) => ({
        url: URLS.RestorePassword,
        data: collectFormData(data, session),
        method: "POST"
      })
    }),
    sendOrderPacks: builder.mutation<{}, { data: { number: string, packNumber: string, itemNumber: string }, session?: Session}>({
      query: ({ data }) => ({
        url: URLS.SendPackNumbers,
        data: collectFormData(data),
        method: 'POST'
      })
    }),

    readChangePopup: builder.mutation<{isSuccess: boolean}, {data : { number: string, itemNumber: string }, session?: Session}>({
      query: ({ data, session }) => ({
        url: URLS.ReadChangePopup,
        data: collectFormData(data, session),
        method: "POST"
      })
    })

  }),

})

export const {
  useGetOrderListQuery,
  useGetClientDevicesQuery,
  useSendSmsCodeMutation,
  useValidSmsCodeMutation,
  useGetShowSetOrderReadyButtonQuery,
  useUpdateOrderStatusMutation,
  useRestorePasswordMutation,
  useSendOrderPacksMutation,
  useReadChangePopupMutation
} = siteApi
