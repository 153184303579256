import { memo, useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import {
  HomePage,
  ThemePage,
  ProfilePage,
  NewRequestPage,
  ProfileEditPage,
  LoginPage,
  PartnerPage,
  HelpPage,
  OrderPage,
  ShopsPage,
  NotFound,
  MyDevices,
} from '.'
import { Contract } from '../components'
import { ChangePassword } from '../components/Login'
import RestorePassword from '../components/Login/ResotrePassword'
import ProfileOrders from '../components/Profile/ProfileOrders'
import { useRedirect } from '../hooks/useRedirect'
import { useSavedData } from '../hooks/useSavedData'
import CreatePage from './CreatePage'
import OrderItemPage from './OrderItemPage'
import PageLayout from './PageLayout'
import ReadyPage from './Ready'
import CourierStockList from './CourierStockList'
import UploadPage from './Upload'
import CourierReceivedPackets from './CourierReceivedPackets'
import CourierRelocationRequest from './CourierTransportApplication'
import { PATHS } from './PATHS'

const WithUrl = memo(() => {
  useRedirect()
  useSavedData()

  const location = useLocation()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (/\/contract\/.+\//.test(location.pathname)) {
        window.document.title =
          `ROSTOCK | Договор купли-продажи № ${location.pathname.replace(
            /\/contract\//,
            '',
          )}`.replace(/\/$/, '')
      } else {
        window.document.title =
          '🌱 Выгодный обмен телефонов в Беларуси | rostock.by'
      }
    }
  }, [location.pathname])

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/change-password/" element={<ChangePassword />} />
      <Route path="/create/" element={<CreatePage />} />
      <Route path="/create/:orderNumber/" element={<CreatePage />} />
      <Route
        path="/create/:orderNumber/:itemNumber/"
        element={<CreatePage />}
      />
      <Route path="/my-devices/" element={<MyDevices />} />
      <Route path="/edit/" element={<ProfileEditPage />} />
      <Route path="/help/" element={<HelpPage />} />
      <Route path="/login/" element={<LoginPage />} />
      <Route path="/order/:orderNumber" element={<OrderPage />} />
      <Route
        path="/order/:orderNumber/:itemNumber/"
        element={<OrderItemPage />}
      />
      <Route path="/ordering/:orderNumber/" element={<h1>afadfdf</h1>} />
      <Route
        path="/order/:orderNumber/:itemNumber/:checkList"
        element={<OrderItemPage />}
      />
      <Route path="/partner/:partnerCode/" element={<PartnerPage />} />
      <Route path="/profile/" element={<ProfilePage />} />
      <Route path="/profile/new_request" element={<NewRequestPage />} />
      <Route
        path="/profile/order-list/"
        element={
          <PageLayout withProfile width="100%">
            {' '}
            <ProfileOrders />
          </PageLayout>
        }
      />
      <Route path="/ready/:itemNumber/" element={<ReadyPage />} />
      <Route path="/restore-password/" element={<RestorePassword />} />
      <Route path="/partners/" element={<ShopsPage />} />
      <Route path="/theme/" element={<ThemePage />} />
      <Route path="/contract/:orderNumber/" element={<Contract />} />
      <Route path="/uploadFiles/" element={<UploadPage />} />
      <Route path={PATHS.stocks} element={<CourierStockList />} />
      <Route
        path={PATHS.COURIER_RECEIVED_PACKETS}
        element={<CourierReceivedPackets />}
      />
      <Route
        path={PATHS.COURIER_TRANSPORT_APPLICATION + ':appNumber/'}
        element={<CourierRelocationRequest />}
      />
      <Route
        path={PATHS.COURIER_TRANSPORT_APPLICATION}
        element={<CourierRelocationRequest />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
})

const RoutesComponent = () => {
  return <WithUrl />
}

export default memo(RoutesComponent)
