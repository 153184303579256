import { memo, ReactNode, useCallback, useEffect } from 'react'
import styled from 'styled-components/macro'
import { useAuthData } from '../../contexts/auth'
import { useMenu, animateTime } from '../../hooks/useMenu'
import Container from '../ui/Container'
import Icon from '../ui/Icon'
import Typography from '../ui/Typography'
import { Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { zIndexes } from '../../zIndexes'
import { UserType } from '../../store/userSlice/types'
import { useAppSelector } from '../../store/hooks'
import { useGetClientDevicesQuery } from '../../store/api/api'
import { Loader } from '../ui'
import { PATHS } from '../../pages/PATHS'

const MenuWrapper = styled.div<{
  visible: boolean
  animationOpen: boolean
  animationClose: boolean
}>`
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  /*overflow: hidden;*/
  padding: 11px 32px 20px;
  z-index: ${zIndexes.menuOverlay};
  text-decoration: none;
  &:before {
    position: absolute;
    left: 42px;
    top: 32px;
    z-index: -1;
    width: ${props => (props.animationOpen ? '20px' : '250vh')};
    height: ${props => (props.animationOpen ? '20px' : '250vh')};
    background: rgba(0, 0, 0, 0.73);
    border-radius: 50%;
    backdrop-filter: blur(8px);
    transform: ${props =>
      props.animationOpen || props.animationClose
        ? 'translate(-50%, -50%)'
        : !props.animationOpen && !props.animationClose
        ? 'translate(-50%, -50%)'
        : 'translate(0, 0)'};
    content: '';
    will-change: width, height, translate;
    animation: ${props =>
      props.animationOpen
        ? `menuAppearance ${animateTime}ms forwards`
        : props.animationClose
        ? `menuAppearance ${animateTime}ms reverse`
        : 'none'};
  }

  @media (min-width: 768px) {
    &::before {
      left: 96px;
      top: 56px;
    }
  }

  @media (orientation: landscape) {
    &::before {
      width: ${props => (props.animationOpen ? '20px' : '250vw')};
      height: ${props => (props.animationOpen ? '20px' : '250vw')};

      animation: ${props =>
        props.animationOpen
          ? `menuAppearanceLandscape ${animateTime}ms forwards`
          : props.animationClose
          ? `menuAppearanceLandscape ${animateTime}ms reverse`
          : 'none'};
    }
  }
`

const Close = styled.svg`
  z-index: ${zIndexes.aboveMenu};
  position: relative;
  margin-right: auto;
  transition: ${props => `color ${animateTime}ms`};
  color: ${props => props.theme.colors.icon.contrast};
  &:hover {
    cursor: pointer;
    color: ${props => props.theme.colors.icon.secondary};
  }
`

const CloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Close
      onClick={onClick}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.63603 14.9499C3.24551 15.3404 3.24551 15.9736 3.63603 16.3641C4.02656 16.7546 4.65972 16.7546 5.05025 16.3641L9.99988 11.4144L14.9497 16.3643C15.3403 16.7548 15.9734 16.7548 16.364 16.3643C16.7545 15.9738 16.7545 15.3406 16.364 14.9501L11.4141 10.0002L16.364 5.05037C16.7545 4.65984 16.7545 4.02668 16.364 3.63615C15.9734 3.24563 15.3403 3.24563 14.9497 3.63615L9.99988 8.58602L5.05025 3.63639C4.65972 3.24586 4.02656 3.24586 3.63603 3.63639C3.24551 4.02691 3.24551 4.66008 3.63603 5.0506L8.58566 10.0002L3.63603 14.9499Z"
        fill="currentColor"
      />
    </Close>
  )
}

const MenuLink = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: ${props => props.theme.colors.link.contrast};
  text-decoration: none;
  transition: color 200ms;

  * {
    text-decoration: none;
  }
  &:hover {
    cursor: pointer;
    color: ${props => props.theme.colors.link.hover};
  }
  &:active {
    color: ${props => props.theme.colors.link.pressed};
  }

  &:focus {
    color: ${props => props.theme.colors.link.default};
  }
`

const Text = ({ children }: { children: ReactNode }) => {
  return (
    <Typography.Button
      noTransition
      styles={{
        color: 'currentcolor',
        marginLeft: '21px',
      }}
    >
      {children}
    </Typography.Button>
  )
}

const NavWrapper = styled.nav<{ visible: boolean }>`
  margin: 122px auto 0;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: ${props =>
    props.visible
      ? `opacity ${animateTime / 2}ms`
      : `opacity ${animateTime}ms`};
`

const linkStyle = {
  width: '100%',
  textDecoration: 'none',
}

const Menu = () => {
  const { hideMenu, menuIsShown, animationOpen, animationClose } = useMenu()
  const { userData, session, logout, userPending } = useAuthData()
  //const { isAdmin } = useSession()
  const location = useLocation()
  const phoneNumber = useAppSelector(state => state.user.login)

  const { data: clientOrder, refetch } = useGetClientDevicesQuery(undefined, {
    refetchOnMountOrArgChange: false,
    skip: true
  })

  useEffect(() => {
    hideMenu()
  }, [location, hideMenu])

  useEffect(() => {
    if (menuIsShown && userData?.userType === UserType.client) {
      refetch()
    }
  }, [menuIsShown, userData])

  const onMenuClick = useCallback((e: any) => {
    if (!e.target.classList.contains('menu-link')) {
      hideMenu()
    }
  }, [])

  return (
    <Fragment>
      {menuIsShown && <CloseButton onClick={hideMenu} />}
      <MenuWrapper
        onClick={onMenuClick}
        className="container"
        visible={menuIsShown}
        animationOpen={animationOpen}
        animationClose={animationClose}
      >
        <NavWrapper visible={menuIsShown}>
          {!userPending && (
            <Container.Flex
              gap={39}
              styles={{
                width: 'fit-content',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              {!session.isAuthorised && (
                <Link style={linkStyle} to={'/login'}>
                  <MenuLink className="menu-link">
                    <Icon name="user" />
                    <Text>Войти</Text>
                  </MenuLink>
                </Link>
              )}

              {session.isAuthorised &&
                (userData?.userType === UserType.partner ||
                  userData?.userType === UserType.employee) &&
                userData && (
                  <Link style={linkStyle} to={'/profile/'}>
                    <MenuLink className="menu-link">
                      <Icon name="user" />
                      <Text>
                        {userData.name.length > 12 && (
                          <>{userData.name.slice(0, 12)}...</>
                        )}
                        {userData.name.length <= 12 && (
                          <>
                            {userData.name}{' '}
                            {userData.lastName &&
                              userData.lastName.slice(0, 1) + '.'}
                          </>
                        )}
                      </Text>
                    </MenuLink>
                  </Link>
                )}

              {session.isAuthorised &&
                userData?.userType === UserType.client &&
                phoneNumber && (
                  <MenuLink
                    className="menu-link"
                    style={{ pointerEvents: 'none' }}
                  >
                    <Icon name="user" />
                    <Text>
                      +{phoneNumber.slice(0, 3)} {phoneNumber.slice(3, 5)} ***
                      ** {phoneNumber.slice(-2)}
                    </Text>
                  </MenuLink>
                )}

              {session.isAuthorised &&
                userData &&
                userData?.userType === UserType.partner && (
                  <>
                    <Link style={linkStyle} to={'/'}>
                      <MenuLink className="menu-link">
                        <Icon name="settings" />
                        <Text>Настройки</Text>
                      </MenuLink>
                    </Link>
                    <Link style={linkStyle} to={'/'}>
                      <MenuLink className="menu-link">
                        <Icon name="users" />
                        <Text>Сотрудники</Text>
                      </MenuLink>
                    </Link>
                  </>
                )}

              {(userData?.userType === UserType.partner ||
                userData?.userType === UserType.employee) && (
                <>
                  <Link style={linkStyle} to={`/profile/order-list/`}>
                    <MenuLink className="menu-link">
                      <Icon name="list" />
                      <Text>Список заявок</Text>
                    </MenuLink>
                  </Link>

                  <Link style={linkStyle} to={`/create/`}>
                    <MenuLink className="menu-link">
                      <Icon name="list-add" />
                      <Text>Новая заявка</Text>
                    </MenuLink>
                  </Link>
                </>
              )}

              {userData?.userType !== UserType.partner &&
                userData?.userType !== UserType.employee &&
                userData?.userType !== UserType.courier && (
                  <>
                    {!!clientOrder?.number && (
                      <Link style={linkStyle} to={`/my-devices/`}>
                        <MenuLink className="menu-link">
                          <Icon name="list" />
                          <Text>Мои устройства</Text>
                        </MenuLink>
                      </Link>
                    )}
                    <Link style={linkStyle} to={`/create/`}>
                      <MenuLink className="menu-link">
                        <Icon name="mobile24" />
                        <Text>Оценить смартфон</Text>
                      </MenuLink>
                    </Link>
                    <Link style={linkStyle} to={`/partners/`}>
                      <MenuLink className="menu-link">
                        <Icon name="leaf24" />
                        <Text>Выбрать партнёра</Text>
                      </MenuLink>
                    </Link>
                    {/* пока что скрыто

                    <Link style={linkStyle} to="/partner/">
                      <MenuLink className="menu-link">
                        <Icon name="reports" />
                        <Text>Стать партнером</Text>
                      </MenuLink>
                    </Link>
                    */}
                  </>
                )}

              {userData?.userType === UserType.courier && (
                <Fragment>
                  <Link style={linkStyle} to={PATHS.stocks}>
                    <MenuLink className="menu-link">
                      <Icon name="list" />
                      <Text>Список складов</Text>
                    </MenuLink>
                  </Link>

                  <Link style={linkStyle} to={PATHS.COURIER_TRANSPORT_APPLICATION}>
                    <MenuLink className="menu-link">
                      <Icon name="add-plus-circle" />
                      <Text>Заявка на перемещение</Text>
                    </MenuLink>
                  </Link>

                  <Link
                    style={linkStyle}
                    to={PATHS.COURIER_RECEIVED_PACKETS}>
                    <MenuLink className="menu-link">
                      <Icon name="mobile24" />
                      <Text>Принятые пакеты</Text>
                    </MenuLink>
                  </Link>
                </Fragment>
              )}

              {session.isAuthorised && (
                <MenuLink className="menu-link" onClick={logout}>
                  <Icon name="exit" />
                  <Text>Выйти</Text>
                </MenuLink>
              )}
            </Container.Flex>
          )}

          {userPending && <Loader />}
        </NavWrapper>
      </MenuWrapper>
    </Fragment>
  )
}

export default memo(Menu)
