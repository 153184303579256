export enum URLS {
  // AUTH
  SendSmsCode = 'auth/sendsmscode/',
  ValidSmsCode = 'auth/validsmscode/',
  RestorePassword = "auth/restorepassword/",
  // ORDER
  GetClientOrder = "orderrequest/getclientorder/",
  ViewCompliteStatus = "orderrequest/viewcomplitestatus/",
  UpdateOrderStatus = "orderrequest/updateorderstatus/",
  GetOrderList = '/orderrequest/getorderlist/',
  SendPackNumbers = '/orderrequest/setorderpacksnumber/',
  ReadChangePopup = '/orderrequest/readchangepopup/',
}