import { ReactNode } from 'react'
import { DeviceInfo } from './store/offerSlice/types'

export type ApiState<T> = {
  pending: boolean
  error: string
} & T

export type ComponentProps = {
  children: ReactNode
}

// API

export type AddCustomerDeviceRequest = {
  deviceNumber: string
  number: string
}

export type AddCustomerDeviceResponse = {
  itemNumber: string
  orderNumber: string
  customerCombinationId: string
  answers: {
    deviceInfo: DeviceInfo
  }
}

export type GetSalePointsResponse = Array<{
  id: string
  name: string
  default: boolean
}>

export type GetPartnerDetailRequest = {
  partnerCode: string
}

export type Address = {
  address: string
  coordinates: string[]
  title: string
}

export type PartnerDetail = {
  id: number
  name: string
  companyLogo: string
  cardDescription: string
  cardTitle: string
  cardColor: string
  partnerDescription: string
  partnerSite: string
  goodsCategories: string[]
  registrationTypes: string[]
  addresses: Address[]
}

export type GetContractDataResponse = {
  number: string
  price: string
  priceText: string
  type: string
  storeAddress: string
  date: string
  company: string
  companyUNP: string
  companyAddress: string
  companyBankAccount: string
  employeePosition: string
  employeeName: string
  employeeDocument: string
  serviceCompany: string
  serviceContract: string
  items: Array<{
    itemName: string
    itemSerial: string
    itemCondition: string
    itemPrice: string
    itemPriceText: string
  }>
}

export enum DeviceStatuses {
  Preliminary = "N",
  Confirming = 'F',
  Confirmed = 'D',
  Changed = 'CF',
  Corresponds = 'CS',
  ChangedAccepted = 'CR',
  Paid = 'P',
  OutOfStock = 'DN'
}

export enum ApiErrorCodes {
  OrderNotExitsts = 'order_not_exist',
}

export type Func = (v: any) => void

export type ChangeBoolState = (v: boolean) => void;
export type ChangeStringState = (v: string) => void;

export type ImageSrc = {
  '1x1': string
  jpg: string
  jpgX2: string
  webp: string
  webpX2: string
}